<template>
	<div id="qvo-app">
		<titlebar />
		<navbar/>
		<main id="content" class="notfound">
			<h1>There's nothing here...</h1>
			<p>We couldn't find the page or resource you're looking for.</p>
			<p>Double-check the URL to make sure you're in the right place.</p>
			<p>
				If you are confident that you have the right URL, 
				please <router-link to="/support">contact our support team</router-link>.
			</p>
		</main>
		<footnote/>
	</div>
</template>

<script>
	//import { mapActions /*, mapState, mapGetters*/ } from 'vuex';

	export default {
		name: 'notfound',
	}
</script>

<!-- Page-Specific Style Import (don't edit here) -->
<style lang="scss">
</style>
